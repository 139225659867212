// BOLT11 Keys
export const COIN_TYPE_KEY = 'coinType';
export const COMPLETE_KEY = 'complete';
export const PAYEE_NODE_KEY = 'payeeNodeKey';
export const PAYMENT_REQUEST_KEY = 'paymentRequest';
export const PREFIX_KEY = 'prefix';
export const RECOVERY_FLAG_KEY = 'recoveryFlag';
export const SATOSHIS_KEY = 'satoshis';
export const MILLISATOSHIS_KEY = 'millisatoshis';
export const SIGNATURE_KEY = 'signature';
export const TIMESTAMP_KEY = 'timestamp';
export const TIMESTAMP_STRING_KEY = 'timestampString';
export const WORDS_TEMP_KEY = 'wordsTemp';
export const TAGS_KEY = 'tags';
export const COMMIT_HASH_KEY = 'purpose_commit_hash';
export const PAYMENT_HASH_KEY = 'payment_hash';
export const FALLBACK_ADDRESS_KEY = 'fallback_address';
export const CODE_KEY = 'code';
export const ADDRESS_KEY = 'address';
export const ADDRESS_HASH_KEY = 'addressHash';
export const DESCRIPTION_KEY = 'description';
export const EXPIRE_TIME = 'expire_time';
export const EXPIRY_HTLC = 'expiry_htlc';
export const MIN_FINAL_CLTV_EXPIRY = 'min_final_cltv_expiry';
export const TIME_EXPIRE_DATE_STRING = 'timeExpireDateString';
export const TIME_EXPIRE_DATE = 'timeExpireDate';
export const timeExpireDate = 'min_final_cltv_expiry';
export const UNKNOWN_TAG_KEY = 'unknownTag';
export const ROUTING_INFO_KEY = 'routing_info';
export const TAG_CODE_KEY = 'tagCode';
export const TAG_WORDS_KEY = 'words';
export const SHORT_CHANNEL_KEY = 'short_channel_id';
export const PUBKEY_KEY = 'pubkey';
export const FEE_PROPORTIONAL_KEY = 'fee_proportional_millionths';
export const FEE_BASE_MSAT_KEY = 'fee_base_msat';
export const CLTV_EXPIRY_DELTA_KEY = 'cltv_expiry_delta';
export const CALLBACK_KEY = 'callback';
export const COMMENT_ALLOWED_KEY = 'commentAllowed';
export const LNURL_TAG_KEY = 'tag';
export const LNURL_K1_KEY = 'k1';
export const DEFAULT_DESCRIPTION_KEY = 'defaultDescription';
export const LNURL_METADATA_KEY = 'metadata';
export const MIN_SENDABLE_KEY = 'minSendable';
export const MAX_SENDABLE_KEY = 'maxSendable';
export const MAX_WITHDRAWABLE_KEY = 'minWithdrawable';
export const MIN_WITHDRAWABLE_KEY = 'minWithdrawable';
export const LN_ADDRESS_DOMAIN_KEY = 'domain';
export const LN_ADDRESS_USERNAME_KEY = 'username';
